import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Home from './components/Home';
import LogRegister from './components/LogRegister/LogRegister';

function App() {

  return (
    <Home />
  );
}

export default App;
